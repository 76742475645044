<i18n locale="th" lang="yaml" src="@i18n/service/payment.th.yaml"></i18n>
<i18n locale="th" lang="yaml" src="@i18n/service/return.th.yaml"></i18n>
<i18n locale="th" lang="yaml" >
return.payment : "สถานะการชำระเงิน"
return.detail : "การรับเครื่องคืน"

service_payment.field.completed_date.display : "ชำระครบเมื่อวันที่ {date}"

service_return.field.remark.placeholder : "หมายเหตุเพิ่มเติมของการส่งคืน เช่น ลูกค้าติดต่อมาให้ช่วยนำส่งคืนให้หน่อย โดยมีการคิดค่าใช้จ่ายในการส่งเพิ่มเติม"
service_return.field.return_by_id.help : "คนที่ทำเรื่องเอาเครื่องออกจากศูนย์"
service_return.field.return_date.help : "เวลาที่เครื่องนี้ออกจากศูนย์ โดยประมาณ เช่น เบิกออกเพื่อส่งต่อ"
service_return.field.return_date.display : "วันที่ {date} "
service_return.field.return_time_range.display : " เวลาโดยประมาณ "

service_refer.field.remark.placeholder : "เช่น ส่งไป HGR เนื่องจากไม่สามารถตรวจหาสาเหตุได้"

return.action.ask_leave.title : "ยืนยันออกจากหน้าจอนี้"
return.action.ask_leave.message : "คุณได้แก้ไขข้อมูลการตรวจรับ และ ยังไม่ได้บันทึกเข้าระบบ, ยืนยันต้องการยกเลิกการแก้ไขนี้ ?"

return.action.ask_reset : "ยกเลิกการแก้ไข"
return.action.ask_reset.title : "@:return.action.ask_reset"
return.action.ask_reset.message : "คุณต้องการยกเลิกการแก้ไข และ นำข้อมูลเดิมในระบบกลับมา ?"
return.action.print_modal : "ใบส่งมอบเครื่อง"

return.action.update.no_change : "ไม่ได้มีการแก้ไขค่า"
return.action.update.confirm.title : "ยืนยันการแก้ไขค่า"
return.action.update.confirm.message : "คุณต้องการบันทึกการแก้ไขข้อมูลการส่งคืน Drone ของงานซ่อมนี้ ?"
return.action.update.confirm.success : "ทำการบันทึกข้อมูลการข้อมูลการส่งคืน Drone ของงานซ่อม {name} เรียบร้อย"

service_return.files : "ข้อมูลอื่น ๆ ประกอบการส่งคืน"
service_return.images.label : "รูป/วีดีโอ ประกอบการส่งมอบ"
service_return.others.label : "ไฟล์ประกอบอื่น ๆ เช่น Log Files"
service_return.document_images.label : "รูปใบส่งมอบเครื่องพร้อมลายเซ็นต์"
service_return.document_files.label : "ไฟล์ใบส่งมอบเครื่องพร้อมลายเซ็นต์"
</i18n>

<template>
	<div v-show="!loading" class="page-padding">
		<template v-if="canUpdateReturn">
			<div class="service-return-container">
				<a-button v-if="returnValue.return_by_id && returnValue.return_type &&
					returnValue.return_date && returnValue.return_time_range && serviceReturn.delivery_method"
					icon="printer" @click="openPrintModal">
					{{$t('return.action.print_modal')}}
				</a-button>
				<a-button class="right-button" @click="handleReset">
					{{$t('return.action.ask_reset')}}
				</a-button>
				<a-button type="primary" class="btn-submit" @click="handleSave">
					{{$t('common.save')}}
				</a-button>
			</div>
			<ErrorMessagePane :error="saveError" />
			<hr />
		</template>

		<template v-if="canViewPayment">
			<div class="mytab-section-title">
				{{$t('return.payment')}}
			</div>
			<div class="return-subsection ">
				<div class="return-row">
					<label>{{$t('service_payment.field.total_fee')}} : </label>
					<span class="return-value">{{payment.total_fee | price}}</span>
				</div>
				<div class="return-row">
					<label>{{$t('service_payment.field.status')}} : </label>
					<div class="return-value">
						{{$tenum('service.payment.status',payment.status)}}
					</div>
					<div v-if="$notEmpty(payment.completed_date)" class="help">
						{{$t('service_payment.field.completed_date.display',{ date: $dayjs(payment.completed_date).format("LL") })}}
					</div>
				</div>
				<div v-if="$notEmpty(payment.payment_remark)" class="return-row">
					<label>{{$t('service_payment.field.payment_remark')}} : </label>
					<div v-trim class="return-value multiline" style="max-width:800px">
						{{payment.payment_remark}}
					</div>
				</div>
			</div>

			<hr />
		</template>

		<div class="mytab-section-title">
			{{$t('return.detail')}}
		</div>
		<div class="return-subsection">
			<div class="return-row">
				<label>{{$t('service_return.field.return_by_id')}}* : </label>
				<template v-if="canUpdateReturn">
					<div v-if="canUpdateReturn" class="return-input">
						<UserCompanySelect
							v-model="returnValue.return_by_id"
							:company-id="service.company_id"
							allow-clear/>
					</div>
					<div class="help">
						{{$t('service_return.field.return_by_id.help')}}
					</div>
				</template>
				<div v-else class="return-value">
					{{returnByUserData.user.username|emptyCheck}}
				</div>
			</div>
			<div class="return-row">
				<label>{{$t('service_return.field.return_type')}}* : </label>
				<template v-if="canUpdateReturnDetail">
					<div class="return-input">
						<a-radio-group v-model="returnValue.return_type" :options="returnTypeOptions"
							@change="handleReturnType"/>
					</div>
				</template>
				<div v-else class="return-value">
					{{$tenum('service.return.type',serviceReturn.return_type)}}
				</div>
			</div>
			<template v-if="returnValue.return_type == 'refer'">
				<div class="divider" />
				<div class="return-row">
					<label>{{$t('service_refer.field.to_company_id')}}* : </label>
					<div v-if="canUpdateReturnDetail" class="return-input">
						<CompanySelect
							v-model="referValue.to_company_id"
							allow-clear/>
					</div>
					<div v-else class="return-value">
						{{referToCompanyData.name}}
					</div>
				</div>
				<div class="return-row">
					<label class="valign-top">{{$t('service_refer.field.remark')}} : </label>
					<a-textarea
						v-if="canUpdateReturnDetail"
						v-model="referValue.remark"
						style="max-width:500px;"
						:auto-size="{ minRows: 4,maxRows: 10 }"
						:placeholder="$t('service_refer.field.remark.placeholder')"/>
					<div v-else v-trim class="return-value multiline">
						{{serviceRefer.remark|emptyCheck}}
					</div>
				</div>
			</template>

			<div class="divider" />
			<div class="return-row">
				<label class="valign-top">{{$t('service_return.field.return_date')}}* : </label>
				<template v-if="canUpdateReturn">
					<div class="return-input">
						<MyDatePicker
							v-model="returnValue.return_date"
							class="return-input-sub"
							style="width:200px"
							format="DD MMMM YYYY"/>
						<div class="return-time-range">
							<a-radio-group
								v-model="returnValue.return_time_range"
								:options="timeRangeOptions"/>
						</div>
					</div>

					<div class="help">
						{{$t('service_return.field.return_date.help')}}
					</div>
				</template>
				<div v-else class="return-value">
					{{$t('service_return.field.return_date.display',{ date: $dayjs(serviceReturn.return_date).format("LL") })}}
					{{$tenum('time_range',serviceReturn.return_time_range)}}
				</div>
			</div>
			<div class="return-row">
				<label class="valign-top">{{$t('service_return.field.delivery_type')}}* : </label>
				<div v-if="canUpdateReturn" class="return-input">
					<DeliveryDetailInput
						ref="rtnDeliveryDetailRef"
						:is-send-out="true"
						:is-customer="returnValue.return_type == 'customer'"/>
				</div>
				<div v-else class="return-value">
					<DeliveryDetailView
						:type="serviceReturn.delivery_method"
						:is-send-out="true"
						:delivery-data="serviceReturn.deliveryData"/>
				</div>
			</div>

			<div class="divider" />
			<div class="return-row full">
				<label>{{$t('service_return.field.remark')}}</label>
				<a-textarea
					v-model="returnValue.remark"
					style="max-width:800px;"
					:auto-size="{ minRows: 4,maxRows: 10 }"
					:read-only="!canUpdateReturn"
					:placeholder="$t('service_return.field.remark.placeholder')"/>
			</div>
			<template v-if="canUpdateReturn || serviceFiles.length > 0 || serviceDocumentFiles.length > 0">
				<div class="mytab-section-title">
					{{$t('service_return.files')}}
				</div>
				<div class="return-subsection files-upload">
					<div class="files-component">
						<ServiceFilesPanel
							ref="serviceFilesRef"
							:error="componentError"
							:service="service"
							:label="$t('service_return.images.label')"
							:others-label="$t('service_return.others.label')"
							:can-update="canUpdateReturn"/>
					</div>
					<div class="files-component">
						<ServiceFilesPanel
							ref="serviceDocumentFilesRef"
							:error="componentError"
							:service="service"
							:label="$t('service_return.document_images.label')"
							:others-label="$t('service_return.document_files.label')"
							:can-update="canUpdateReturn"/>
					</div>
				</div>
			</template>
		</div>

		<div v-if="canUpdateReturn" class="page-action-right">
			<hr />
			<a-button @click="handleReset">
				{{$t('return.action.ask_reset')}}
			</a-button>
			<a-button type="primary" class="btn-submit" @click="handleSave">
				{{$t('common.save')}}
			</a-button>
		</div>
		<template>
			<div>
				<ServiceDeliveryPrintModal
					ref="deliveryPrintModalRef"
					:payment="payment"
					:return-by-user-data="returnByUserData"
					:return-value="serviceReturn"
					:refer-value="serviceRefer"/>
			</div>
		</template>
	</div>
</template>

<script>
import ServiceDeliveryPrintModal from '@components/service/ServiceDeliveryPrintModal.vue'
import PageMixin from "@mixins/PageMixin.vue"
import ServiceObjectMixin from "@/src/mixins/service/ServiceObjectMixin.vue"
import ErrorMessagePane from "@components/common/ErrorMessagePane.vue"
import MyDatePicker from "@components/input/MyDatePicker.vue"
import CompanySelect from "@components/company/CompanySelect.vue"
import UserCompanySelect from "@components/user/UserCompanySelect.vue"
import DeliveryDetailInput from "@components/input/DeliveryDetailInput.vue"
import DeliveryDetailView from "@components/common/DeliveryDetailView.vue"
import {Radio} from "ant-design-vue"
import {scrollTopContent,getEnumSelectOptions} from "@utils/formUtil"
import axios from "axios"
import _isEqual from "lodash/isEqual"
import {replaceDeepProperty,copyDeep,transformToJsonProp,emptyUser} from "@utils/objectUtil"
import FormError from '@utils/errors/FormError'
import ServiceFilesPanel from "@components/service/ServiceFilesPanel.vue"
import ModelError from "@utils/errors/ModelError"

function _defaultReturn() {
	return {
		'return_type' : undefined,
		'delivery_method' : undefined,
		'return_date' : undefined,
		'return_time_range' : undefined,
		'return_by_id' : undefined,

		'remark' : undefined,
	}
}
function _defaultRefer() {
	return {
		to_company_id : undefined ,
		remark : undefined,
	}
}
function _defaultPreDelivery() {
	return {
		is_not_inspect : false,
		flight_time_sec : undefined,
		inspected_by_id : undefined,
		remark : undefined,
	}
}
export default {
	components : {
		"a-radio-group" : Radio.Group ,
		ErrorMessagePane , MyDatePicker , DeliveryDetailInput,
		UserCompanySelect, CompanySelect , DeliveryDetailView,
		ServiceDeliveryPrintModal, ServiceFilesPanel
	} ,
	mixins : [PageMixin,ServiceObjectMixin] ,
	props : {
		service : {
			type : null,
			default : () => []
		} ,
	} ,
	data() {
		return {
			loading : false,
			payment : {} ,
			serviceReturn : {} ,
			serviceRefer : {} ,
			serviceFiles: [],
			serviceDocumentFiles: [],

			returnValue : _defaultReturn() ,
			referValue : _defaultRefer() ,
			preDeliveryValue : _defaultPreDelivery(),

			ignorePreventExit : false,
			oldFormData : {} ,
			saveError : undefined,
		}
	} ,
	computed : {
		canUpdateReturnDetail() {
			return this.canUpdateReturn && this.serviceRefer.id > 0	&&
				!this.serviceRefer.refer_service_id
		} ,
		timeRangeOptions() {
			const options = ['morning','noon','afternoon','evening']
			return getEnumSelectOptions(this,'time_range',options)
		} ,
		returnTypeOptions() {
			const options = ['customer','refer']
			return getEnumSelectOptions(this,'service.return.type',options)
		} ,
		returnByUserData() {
			return this.serviceReturn.returnByData ? this.serviceReturn.returnByData : emptyUser()
		} ,
		referToCompanyData() {
			return this.serviceRefer.toCompanyData ? this.serviceRefer.toCompanyData : {}
		} ,
		componentError() {
			return this.saveError instanceof ModelError ? this.saveError : undefined
		}
	} ,
	watch : {
		$route(newVal) {
			if (!this.payment || newVal.params.id != this.payment.id) {
				this.fetchData()
				this.fetchPreDelivery()
			}
		}
	} ,
	mounted() {
		this.fetchData();
		this.fetchPreDelivery();
	} ,
	beforeMount() {
		window.addEventListener("beforeunload",this.preventExit)
	} ,
	beforeDestroy() {
		window.removeEventListener("beforeunload",this.preventExit)
	} ,
	methods : {
		openPrintModal () {
			this.$refs.deliveryPrintModalRef.openModal({
				serviceId: this.service.id,
				flightTime: this.preDeliveryValue.flight_time_sec,
				isDeviceOnly: this.service.is_device_only,
				serviceNumber: this.service.service_no,
				droneSerial: this.service.droneData.drone.serial_no,
				dronePixhawk: this.service.droneData.drone.pixhawk_serial_no,
				droneModelName: this.service.droneData.model.name,
				customerName: this.service.customer_name,
				customerPhone: this.service.customer_phone,
				customerProvince: this.service.customer_province,
				owner: this.service.droneData.owner,
				openDate: this.service.open_date,
				openBy: this.service.openByData.user.full_name,
				technician: this.service.technicianData ? this.service.technicianData.user.full_name : '',
			})
		},
		fetchData() {
			const serviceId = this.$route.params.id
			if (!serviceId) {
				return
			}
			if (!this.canViewReturn) {
				return this.$open({name : 'service/view',params: {id : serviceId}})
			}
			this.loading = true
			axios.get("/api/services/"+serviceId+"/return").then((response) => {
				this.payment = response.data.data.payment
				this.serviceFiles = response.data.data.service_files
				this.serviceDocumentFiles = response.data.data.service_document_files
				this.serviceReturn = response.data.data.return
				this.serviceRefer = response.data.data.refer
				this.initReturn()
			}).catch((error) => {
				this.fetchError(error)
			}).finally(() => {
				this.loading = false
			})
		} ,
		fetchPreDelivery() {
			const serviceId = this.$route.params.id
			if (!serviceId) {
				return
			}
			this.loading = true
			axios.get(`/api/services/${serviceId}/pre-delivery`).then((response) => {
				this.preDelivery = response.data.data.pre_delivery
				this.initPreDelivery()
			}).catch((error) => {
				this.fetchError(error)
			}).finally(() => {
				this.loading = false
			})
		} ,
		initPreDelivery() {
			this.saveError = undefined
			this.inspectedDate = undefined
			this.inspectedTime = undefined

			this.preDeliveryValue = _defaultPreDelivery()
			if (this.preDelivery) {
				replaceDeepProperty(this.preDeliveryValue,this.preDelivery)
			}
		} ,
		initReturn() {
			this.saveError = undefined
			this.returnValue = _defaultReturn()
			this.referValue = _defaultRefer()
			if (this.serviceReturn) {
				replaceDeepProperty(this.returnValue,this.serviceReturn)
			}
			if (this.serviceRefer) {
				replaceDeepProperty(this.referValue,this.serviceRefer)
			}
			this.$nextTick(() => {
				if (this.$refs.rtnDeliveryDetailRef) {
					this.$refs.rtnDeliveryDetailRef.setData(this.returnValue.delivery_method,this.serviceReturn.deliveryData)
				}
				this.oldFormData = this.dumpFormData()
				if (this.$refs.serviceFilesRef) {
					this.$refs.serviceFilesRef.setData(this.serviceFiles)
				}
				if (this.$refs.serviceDocumentFilesRef) {
					this.$refs.serviceDocumentFilesRef.setData(this.serviceDocumentFiles)
				}
			})
		} ,
		handleReturnType() {
			if (this.returnValue.return_type != 'refer') {
				this.referValue = _defaultRefer()
			}
		} ,
		validateFormData(formData) {
			this.saveError = undefined
			const formError = new FormError(this)

			if (this.canUpdateReturnDetail && this.returnValue.return_type == 'refer') {
				if (this.referValue.to_company_id === undefined || this.referValue.to_company_id <= 0) {
					formError.addMessage(
						'refer',
						this.$t("validate.required",{field : this.$t('service_refer.field.to_company_id')}),
						this.$t('service_refer.name')
					)
				}
			}

			if (formError.hasErrors()) {
				this.saveError = formError
				this.$nextTick(()=>{
					scrollTopContent()
				})
				return false
			} else {
				return true
			}
		} ,
		dumpFormData() {
			const returnForm = copyDeep(this.returnValue)
			if (this.$refs.rtnDeliveryDetailRef) {
				const data = this.$refs.rtnDeliveryDetailRef.getData()
				returnForm.delivery_method = data.type
				returnForm.deliveryData = data.data
			} else {
				returnForm.delivery_method = undefined
				returnForm.deliveryData = null;
			}
			const formData = {
				'return' : returnForm,
				service_files: []
			}
			if (this.canUpdateReturnDetail && returnForm.return_type == 'refer') {
				const referForm  = copyDeep(this.referValue)
				formData.refer = referForm
			}
			if (this.$refs.serviceFilesRef) {
				formData.service_files.push({upload_type: 'delivery', files: this.$refs.serviceFilesRef.getData()})
			}
			if (this.$refs.serviceDocumentFilesRef) {
				formData.service_files.push({upload_type: 'delivery-doc', files: this.$refs.serviceDocumentFilesRef.getData()})
			}
			return formData
		} ,
		handleReset() {
			if (!this.canUpdateReturn)
				return
			const formData = this.dumpFormData()
			if (!this.isDataChange(formData)) {
				scrollTopContent()
				return
			}
			this.$confirm({
				title : this.$t('return.action.ask_reset.title') ,
				content : this.$t('return.action.ask_reset.message') ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk: ()=> {
					this.initReturn()
					scrollTopContent()
				} ,
			})
		} ,
		handleSave() {
			if (!this.canUpdateReturn)
				return

			const formData = this.dumpFormData()
			if (!this.isDataChange(formData)) {
				this.$message.info(this.$t('return.action.update.no_change'))
				return
			}
			if (!this.validateFormData(formData)) {
				return
			}

			this.$confirm({
				title : this.$t('return.action.update.confirm.title') ,
				content : this.$t('return.action.update.confirm.message') ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk: ()=> {
					this.showPageLoading(true)
					transformToJsonProp(formData.return,'deliveryData','delivery_json')

					axios.put(`/api/services/${this.service.id}/return`,formData).then((response)=>{
						this.ignorePreventExit = true
						this.$message.success(this.$t('return.action.update.confirm.success',{name:this.service.service_no}))
						this.$router.go()
					}).catch((error) => {
						this.saveError = error
					}).finally(()=>{
						this.hidePageLoading()
					})
				} ,
			})
		} ,
		preventExit(event) {
			if (this.checkPreventExit()) {
				event.preventDefault()
				event.returnValue = ""
			}
		},
		isDataChange(formData) {
			const change = !_isEqual(this.oldFormData,formData)
			return change
		} ,
		checkPreventExit(formData=null) {
			if (!this.canUpdateReturn || this.ignorePreventExit)
				return false
			if(!formData) {
				formData = this.dumpFormData()
			}
			return this.isDataChange(formData)
		}
	} ,
	beforeRouteLeave(routeTo,routeFrom,next) {
		const formData = this.dumpFormData()
		if (this.checkPreventExit(formData)) {
			this.$confirm({
				title : this.$t('return.action.ask_leave.title') ,
				content : this.$t('return.action.ask_leave.message') ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk: ()=> {
					next()
				} ,
			})
		} else {
			next()
		}
	}
}
</script>

<style lang="less" scoped>
.return-subsection {
	margin-left : 64px;
	margin-bottom : 32px;
	.mobile & {
		margin-left : 16px;
	}
	.divider {
		border-bottom : 1px dashed @border-color-base;
		height : 1px;
		max-width: 800px;
		margin : 20px 0;
	}
}
.return-row {
	margin-bottom : 12px;
	.mobile {
		margin-bottom : 24px;
	}
	> label {
		color : @info-color;
		width : 150px;
		text-align: right;
		margin-right : 8px;
		display : inline-block;
		.mobile & {
			display : block;
			text-align: left;
			margin-bottom : 2px;
			width : auto;
		}
	}
	&.full > label {
		text-align : left;
		display : block;
		margin-bottom : 4px;
	}
	.return-value {
		display : inline-block;
		color : @primary-color;
	}
	.return-input {
		display : inline-block;
		.mobile & {
			display : block;
		}
	}
	.return-time-range {
		display : block;
		margin-top : 8px;
		margin-bottom : 4px;
	}
	.help {
		color : @text-muted;
		margin-top : 2px;
		font-size : 0.95em;
		margin-left : 158px;
		.mobile & {
			margin-left : 0;
		}
	}
}
.service-return-container {
	display: flex;
	flex-direction : row;
	justify-content : space-between;
}
.right-button {
	margin: 0 0.2rem 0 auto;
}
.files-component {
	margin-right: 2rem;
}
.files-upload {
	display: flex;
	flex-direction: row;
	margin-right: 2rem;
}
</style>

import { render, staticRenderFns } from "./ServiceReturn.vue?vue&type=template&id=80f79298&scoped=true"
import script from "./ServiceReturn.vue?vue&type=script&lang=js"
export * from "./ServiceReturn.vue?vue&type=script&lang=js"
import style0 from "./ServiceReturn.vue?vue&type=style&index=0&id=80f79298&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80f79298",
  null
  
)

/* custom blocks */
import block0 from "@i18n/service/payment.th.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fservice%2FServiceReturn.vue&locale=th&lang=yaml&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@i18n/service/return.th.yaml?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fservice%2FServiceReturn.vue&locale=th&lang=yaml&external"
if (typeof block1 === 'function') block1(component)
import block2 from "./ServiceReturn.vue?vue&type=custom&index=2&blockType=i18n&locale=th&lang=yaml"
if (typeof block2 === 'function') block2(component)

export default component.exports
<i18n locale="th" lang="yaml" src="@i18n/service/service.th.yaml"></i18n>
<i18n locale="th" lang="yaml" src="@i18n/service/payment.th.yaml"></i18n>
<i18n locale="th" lang="yaml" >
delivery.main_title : "ใบส่งมอบโดรน"
delivery.number_title : "เลขที่เอกสาร:"
delivery.service_number : "{serviceNumber}"
delivery.header.page_date : "เวลาที่พิมพ์: {datetime}"
delivery.header.company_info : "ศูนย์บริการ"
delivery.header.drone : "Drone"
delivery.header.drone.serial : "เลขเครื่อง: {serial}"
delivery.header.drone.pixhawk : "({pixhawk})"
delivery.header.drone.model : "รุ่น: {name}"
delivery.header.report_by : "รับแจ้งโดย"

delivery.header.drone_receive_date : "โดรนเข้าศูนย์วันที่"
delivery.header.inspection_date : "วันที่ตรวจเชคโดรน ก่อนส่งมอบ"
delivery.header.flight_time : "จำนวนชั่วโมงบิน"
delivery.header.firmware : "Firmware"
delivery.header.devices : "อุปกรณ์ที่มากับ Drone"
delivery.header.devices_without_drone : "อุปกรณ์"
delivery.header.devices_unspecified: "ไม่ระบุ"

delivery.footer.consent.acceptance : "&nbsp;&nbsp;( ) ข้าพเจ้าได้ตรวจสอบและยืนยันความถูกต้องของรายการข้างต้นเรียบร้อยแล้ว และรับมอบโดรนพร้อมอุปกรณ์เป็นที่เรียบร้อย"
delivery.footer.signature : "<div>..................................</div><pre>(                  )</pre><div>{description}</div>"
delivery.footer.signature.customer : "ผู้เข้ารับบริการ"
delivery.footer.signature.inspect_by : "ผู้ตรวจสอบ"
delivery.footer.signature.deliver_by : "ผู้รับมอบ"
delivery.footer.signature.approve_by : "ผู้อนุมัติ"
delivery.footer.remark : "หมายเหตุ: "

delivery.return_by : "ทำเรื่องโดย"
delivery.return_to : "ส่งมอบให้กับ"
delivery.return_date : "วันที่ส่งมอบ"
delivery.return_method : "วิธีการส่งมอบ"
delivery.refer : "*งานนี้ถูกส่งมอบจากศูนย์ {sender} ไปยังศูนย์ {receiver}"

delivery.return.payment : "สถานะการชำระเงิน"
delivery.return.detail : "การรับเครื่องคืน"

delivery_payment.field.completed_date.display : "ชำระครบเมื่อวันที่ {date}"
delivery_modal.pageLoading.tip : "กำลัง Load ข้อมูล, กรุณารอสักครู่ ..."

</i18n>


<template>
	<a-modal class="printing-modal" :width="800" :visible="visible" :footer="null" :mask-closable="false" @cancel="handleCancel">
		<a-spin wrapper-class-name="tunjai-page-loading" :spinning="loading" size="large" :tip="$t('delivery_modal.pageLoading.tip')">
			<div id="print-pre-delivery">
				<div class="page-header">
					<img class="hg-logo" src="@assets/images/hiveground_logo.png"/>
					<div class="modal-name">
						<my-page-header :title="$t('delivery.main_title')" />
					</div>
					<div v-if="setFormData" class="service-no">
						<div style="font-size: 13px;" class="service-no-title">{{$t('delivery.number_title')}}</div>
						<div style="font-size: 13px;">{{$t('delivery.service_number', { serviceNumber: serviceNumber })}}</div>
						<div class="form-detail">
							<span class="service-no-title">{{ $t('service.header.page_form_title') }}</span>
							{{ $t('service.header.page_form_content', { form: setFormData.formCode }) }}
						</div>
						<div class="form-detail">
							<span class="service-no-title">{{ $t('service.header.page_revision_title') }}</span>
							{{ $t('service.header.page_revision_content', { revision: setFormData.revision }) }}
						</div>
						<div class="form-detail">
							<span class="service-no-title">{{ $t('service.header.page_effective_date_title') }}</span>
							{{ $t('service.header.page_effective_date_content', { effectiveDate: setFormData.effectiveDate }) }}
						</div>
					</div>
				</div>

				<div class="service-detail-container">
					<div class="service-col">
						<label>{{ $t('delivery.header.company_info') }}</label>
						<div class="service-val">
							<div class="service-val-row">
								{{ displayCompany.name }}
							</div>
							<div class="service-val-row">
								{{
									(displayCompany.address ? displayCompany.address + ' ' : '') + displayCompany.province + ' ' + displayCompany.postal_code
								}}
							</div>
							<div v-if="displayCompany.contact_phone" class="service-val-row">
								<PhoneLink :value="displayCompany.contact_phone" />
							</div>
							<div v-if="displayCompany.contact_email" class="service-val-row info">
								{{ displayCompany.contact_email }}
							</div>
						</div>
					</div>

					<div class="service-col">
						<label>{{ $t('delivery.header.drone') }}</label>
						<div class="service-val">
							<div class="service-val-row">
								{{ $t('delivery.header.drone.serial', { serial: droneSerial }) }}
							</div>
							<div class="service-val-row">
								{{ $t('delivery.header.drone.pixhawk', { pixhawk: dronePixhawk }) }}
							</div>
							<div class="service-val-row">
								{{ $t('delivery.header.drone.model', { name: droneModelName }) }}
							</div>
							<div class="service-val-row">
									{{ $t('service.header.drone_owner', { name: ownerName }) }}
							</div>
							<div v-if="ownerIdentityNo" class="service-val-row">
								{{ $t('service.header.drone_owner_identity_no', { id: ownerIdentityNo }) }}
							</div>
							<div v-else class="service-val-row">
								{{ $t('service.header.drone_owner_identity_no_blank') }}
							</div>
							<div class="service-val-row">
								<PhoneLink :value="ownerPhone" />
								<span style="margin-left: 8px">{{ $t('common.province') }}{{ ' ' + ownerProvince }}</span>
							</div>
						</div>
					</div>

					<div class="service-col">
						<label>{{ $t('service.field.customer') }}</label>
						<div v-if="isSamePerson" class="service-val">
							<div class="service-val-row">
								{{ ownerName }}
							</div>
							<div v-if="ownerIdentityNo" class="service-val-row">
								{{ $t('service.header.drone_owner_identity_no', { id: ownerIdentityNo }) }}
							</div>
							<div v-else class="service-val-row">
								{{ $t('service.header.drone_owner_identity_no_blank') }}
							</div>
							<div class="service-val-row">
								<PhoneLink :value="ownerPhone" />
								<span style="margin-left: 8px">{{ $t('common.province') }}{{ ' ' + ownerProvince }}</span>
							</div>
						</div>
						<div v-else class="service-val">
							<div class="service-val-row">
								{{ customerName }}
							</div>
							<div class="service-val-row">
								{{ $t('service.header.drone_owner_identity_no_blank')}}
							</div>
							<div class="service-val-row">
								<PhoneLink :value="customerPhone" />
								<span style="margin-left: 8px">{{ $t('common.province') }}{{ ' ' + customerProvince }}</span>
							</div>
						</div>
					</div>

					<div class="service-col">
						<label>{{ $t('delivery.header.drone_receive_date') }}</label>
						<div class="service-val">
							<div class="service-val-row">
								<span v-if="droneReceiveDateTime">
									{{ droneReceiveDateTime }}
								</span>
								<span v-else>
									{{ $t('common.unspecify') }}
								</span>
							</div>
						</div>
					</div>

					<div class="service-col">
						<label>{{ $t('delivery.header.report_by') }}</label>
						<div class="service-val">
							<div class="service-val-row">
								<span v-if="openByUsername">
									{{ openByUsername }}
								</span>
								<span v-else>
									{{ $t('common.unspecify') }}
								</span>
							</div>
						</div>
					</div>

					<div v-if="!deviceOnly" class="service-col">
						<label>{{ $t('delivery.header.flight_time') }}</label>
						<div class="service-val">
							<FlightTimeView :time-seconds="flightTime" />
						</div>
					</div>
					<div v-if="!deviceOnly" class="service-col">
						<label>{{ $t('delivery.header.firmware') }}</label>
						<div class="service-val">
							{{firmwareVersion}}
						</div>
					</div>

					<div class="service-col">
						<label> {{deviceOnly ? $t('delivery.header.devices_without_drone') : $t('delivery.header.devices')}}</label>
						<div class="service-val" style="display:flex; flex-direction:column;">
							<div v-for=" item in devices" :key="item.id" >
								{{$tenum('drone.devices',item)}}
							</div>
							<div v-if="others !== ''" class="service-val">
								{{others}}
							</div>
						</div>
					</div>
				</div>

				<div class="checklist-row checklist-row-title checklist-row-border">
					<label class="title"> <span>1</span> {{ $t('delivery.return.payment') }} </label>
				</div>
				<div class="return-subsection ">
					<div class="return-row">
						<label>{{$t('service_payment.field.total_fee')}} : </label>
						<span class="return-value">{{payment.total_fee | price}}</span>
					</div>
					<div class="return-row">
						<label>{{$t('service_payment.field.status')}} : </label>
						<div class="return-value">
							{{$tenum('service.payment.status',payment.status)}}
						</div>
						<div v-if="$notEmpty(payment.completed_date)" class="help">
							{{$t('delivery_payment.field.completed_date.display',{ date: $dayjs(payment.completed_date).format("LL") })}}
						</div>
					</div>
					<div v-if="$notEmpty(payment.payment_remark)" class="return-row">
						<label>{{$t('service_payment.field.payment_remark')}} : </label>
						<div v-trim class="return-value multiline" style="max-width:800px">
							{{payment.payment_remark}}
						</div>
					</div>
				</div>
				<div class="checklist-row checklist-row-title checklist-row-border">
					<label class="title"> <span>2</span> {{ $t('delivery.return.detail') }} </label>
				</div>
				<div class="return-subsection ">
					<div class="return-row">
						<label>{{$t('delivery.return_by')}} : </label>
						<span class="return-value">{{returnByUserData.user.full_name}}</span>
					</div>
					<div class="return-row">
						<label>{{$t('delivery.return_to')}} : </label>
						<div class="return-value">
							{{customerName}}
						</div>
					</div>
					<div class="return-row">
						<label>{{$t('delivery.return_date')}} : </label>
						<div class="return-value">
							{{returnValue.return_date}}
						</div>
					</div>
					<div class="return-row">
						<label>{{$t('delivery.return_method')}} : </label>
						<div class="return-value">
							{{$tenum('drone.return',returnValue.delivery_method)}}
						</div>
					</div>
				</div>

				<div v-if="returnValue.return_type === 'refer'" class="refer-text">
					{{$t('delivery.refer', { sender: displayCompany.name, receiver: referValue.toCompanyData.name })}}
				</div>
				<div v-html="$t('delivery.footer.consent.acceptance')" />
				<div class="signature">
					<div
						class="signature-item"
						v-html="$t('delivery.footer.signature', { description: $t('delivery.footer.signature.customer') })"/>
					<div
						class="signature-item"
						v-html="$t('delivery.footer.signature', { description: $t('delivery.footer.signature.inspect_by') })"/>
					<div
						class="signature-item"
						v-html="$t('delivery.footer.signature', { description: $t('delivery.footer.signature.deliver_by') })"/>
					<div
						class="signature-item"
						v-html="$t('delivery.footer.signature', { description: $t('delivery.footer.signature.approve_by') })"/>
				</div>
				<div class="page-remark">
					{{returnValue.remark ? $t('delivery.footer.remark') + returnValue.remark : $t('delivery.footer.remark') + '-' }}
				</div>
			</div>

			<a-button type="info" icon="printer" @click="print"> print </a-button>
		</a-spin>
	</a-modal>
</template>

<script>
import { mapState } from 'vuex'
import PhoneLink from '@components/common/PhoneLink.vue'
import FlightTimeView from '@components/common/FlightTimeView.vue'
import axios from 'axios'
import ApiError from '@utils/errors/ApiError'
import { findFormData, FORM_CODE } from '@utils/documentUtil'

export default {
	components: {
		PhoneLink,
		FlightTimeView,
	},

	props: {
		payment: {
			type: null,
			default: () => {},
		},
		returnByUserData: {
			type: null,
			default: () => {},
		},
		returnValue: {
			type: null,
			default: () => {},
		},
		referValue: {
			type: null,
			default: () => {},
		}
	},

	data() {
		return {
			visible: false,
			loading: false,
			flightTime: '',
			droneReceiveDateTime: '',
			deviceOnly: false,
			serviceId: '',
			serviceNumber: '',
			droneSerial: '',
			dronePixhawk: '',
			droneModelName: '',
			customerName: '',
			customerPhone: '',
			customerProvince: '',
			printDate: '',
			openByUsername: '',
			firmwareVersion: '',
			devices: [],
			others: '',
			remark: '',
			ownerName: '',
			ownerIdentityNo: null,
			ownerPhone: '',
			ownerProvince: '',
			isSamePerson: false,
		}
	},

	computed: {
		...mapState('user', ['companies']),
		...mapState('auth', ['currentCompany']),
		...mapState('form', ['forms']),
		displayCompany() {
			return this.companies.find((c) => c.id == this.currentCompany.id)
		},
		setFormData() {
			const formCode = FORM_CODE.DELIVERY
			return findFormData(this.forms, formCode)
		}
	},

	methods: {
		setData(data) {
			this.$nextTick(() => {
				this.$refs.checklistRef.setData(data)
			})
		},
		_loadPreServcie() {
			this.loading = true
			axios
				.get(`/api/services/${this.serviceId}/pre-service`)
				.then((response) => {
					this.firmwareVersion = response.data.data.pre_service.firmware ? response.data.data.pre_service.firmware.name : `$t(delivery.header.devices_unspecified)`
					this.remark = response.data.data.pre_service.remark
					if (response.data.data.pre_service.devicesData) {
						this.devices = response.data.data.pre_service.devicesData.devices.length === 0 ? [`$t(delivery.header.devices_unspecified)`] : response.data.data.pre_service.devicesData.devices
						this.others = response.data.data.pre_service.devicesData.others
					} else {
						this.devices = [`$t(delivery.header.devices_unspecified)`]
					}
					if (response.data.data.pre_service.inspected_datetime) {
						const date = this.$dayjs(response.data.data.pre_service.inspected_datetime)
						this.droneReceiveDateTime = date.format('YYYY-MM-DD') + ' เวลา ' + date.format('HH:mm')
					}
				})
				.catch((error) => {
					this.$message.error(ApiError.getDisplayErrorMessage(this, error))
					this.visible = false
				})
				.finally(() => {
					this.loading = false
				})
		},

		openModal(data) {
			this.visible = true
			this.serviceId = data.serviceId
			this.deviceOnly = data.isDeviceOnly
			this.flightTime = data.flightTime
			this.serviceNumber = data.serviceNumber
			this.droneSerial = data.droneSerial
			this.dronePixhawk = data.dronePixhawk
			this.droneModelName = data.droneModelName
			this.customerName = data.customerName
			this.customerPhone = data.customerPhone
			this.customerProvince = data.customerProvince
			this.openByUsername = data.openBy
			this.ownerName = data.owner.name
			this.ownerIdentityNo =  data.owner.identity_no
			this.ownerPhone = data.owner.phone
			this.ownerProvince = data.owner.province
			this.isSamePerson = this.customerName === this.ownerName
			const currentDateTime = new Date()
			this.printDate = currentDateTime.toLocaleDateString() + ':' + currentDateTime.toLocaleTimeString()
			this._loadPreServcie()
		},

		handleCancel() {
			this.visible = false
		},

		print() {
			const prtHtml = document.getElementById('print-pre-delivery').innerHTML
			let stylesHtml = ''

			for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
				stylesHtml += node.outerHTML
			}

			// Open the print window
			const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')
			WinPrint.document.write(`<!DOCTYPE html>
				<html>
  				<head>
    				${stylesHtml}
  				</head>
  				<body>
    				${prtHtml}
  				</body>
				</html>`)
			setTimeout(()=> {
				WinPrint.focus()
				WinPrint.document.close()
				WinPrint.print()
				WinPrint.close()
			}, 2000)
		},
	},
}
</script>

<style lang="less" scoped>
@part-break-screen: @screen-lg;

.printing-modal {
	width: 700px;
	div {
		font-size: 0.7rem;
	}
}
.info {
	color: @info-color;
}
.page-header {
	display: flex;
	flex-direction: row;
	padding-top: 15px;
  justify-content: space-between;
}
.service-no {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	padding-left: 30px;
}
.service-no-title {
	color: @primary-color;
}
.form-detail {
	font-style: italic;
}
.modal-name {
	justify-content: center;
	align-items: center;
	text-align: center;
}
.hg-logo {
  max-width: 25%;
  height: 40%;
}
.service-detail-container {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	background-color: @white;
	margin-bottom: 8px;
	border-radius: @border-radius-base;
	.service-col {
		flex-basis: 50%;
		display: flex;
		flex-wrap: nowrap;
		min-width: 0;
		border: 1px solid @gray-4;
		> label {
			font-family: @font-family-title;
			color: @primary-color;
			min-width: 100px;
			flex-basis: 100px;
			background-color: @gray-3;
			padding: 4px 8px;
		}
		> .service-val {
			padding: 4px 8px;
			min-width: 0;
			.service-val-row {
				margin-bottom: 2px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}
.return-row {
	margin-top: 12px;
	margin-bottom : 12px;
	.mobile {
		margin-bottom : 24px;
	}
	> label {
		color : @info-color;
		width : 150px;
		text-align: right;
		margin-right : 8px;
		display : inline-block;
		.mobile & {
			display : block;
			text-align: left;
			margin-bottom : 2px;
			width : auto;
		}
	}
	&.full > label {
		text-align : left;
		display : block;
		margin-bottom : 4px;
	}
	.return-value {
		display : inline-block;
		color : @text-color;
	}
	.return-input {
		display : inline-block;
		.mobile & {
			display : block;
		}
	}
	.return-time-range {
		display : block;
		margin-top : 8px;
		margin-bottom : 4px;
	}
	.help {
		color : @text-muted;
		margin-top : 2px;
		font-size : 0.95em;
		margin-left : 158px;
		.mobile & {
			margin-left : 0;
		}
	}
}
.consent {
	margin-top: 16px;
}
.signature {
	display: flex;
	position: relative;
	padding: 10px;
	margin-top: 8px;
	margin-bottom: 0px;
	.signature-item {
		display: flex;
		flex-basis: 25%;
		align-items: center;
		flex-direction: column;
		position: relative;
		margin-right: 8px;
	}
}
.page-remark {
	display: flex;
	margin-top: 8px;
	justify-content: left;
	align-items: left;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.refer-text {
	color: @primary-color;
	margin-bottom: 2rem;
}
@media print {
	div {
		font-size: 0.7rem;
	}
	.service-col {
		> label {
			-webkit-print-color-adjust: exact;
		}
	}
	.pagebreak {
		clear: both;
		page-break-after: always;
	}
}
</style>
